<template>
    <div>
        <Sidebar
            width="1545px"
            :defaultLayout="false"
            :visible="isTaskDetailSideBar"
            @update:visible="() => $emit('toggleTaskDetail', task)"
            className="task-detail-sidebar"
            headClass="task-detail-head"
            :zIndex="props.zIndex"
            :top="props.top"
        >
            <template #head-left>
                <div class="d-flex">
                    <div class="d-flex align-items-center">
                        <img :src="sidebarArrowIcon" v-if="!task.isParentTask" alt="sidebarArrowIcon" class="cursor-pointer mr-10px" style="width: 25px; height: 25px;" @click.stop="open('parent')"/>
                    </div>
                    <div>
                        <Skelaton v-if="isSpinner" class="breadcrubm-skeleton"/>
                        <TaskDetailNavBar
                            v-if="task !== undefined && clientWidth > 764 && !isSpinner && !isSupport"
                            :taskKey="task.TaskKey"
                            :isParent="task.isParentTask"
                            :sprintName="task.sprintName ? task.sprintName : ''"
                            :isFolderSprint="task.sprintArray && task.folderObjId ? true : false"
                            :folderName="task.folderName ? task.folderName : ''"
                            :parentKey="parentTask ? parentTask.TaskKey : ''"
                            :projectName="projectData ? projectData.ProjectName : ''"
                            @open="(val) => open(val)"
                        />
                        <TaskDetailTitle
                            v-if="task !== undefined && !isSpinner"
                            :taskName="task.TaskName"
                            :isSupport="isSupport"
                            :taskType="task.TaskTypeKey"
                            :favourites="task.favouriteTasks"
                            :userId="user.id"
                            @update:favourite="(val) => updateFavourite()"
                            @update:taskName="(val) => updateTaskName(val)"
                        />
                    </div>
                </div>
            </template>
            <template #head-right>
                <TaskDetailAction
                    v-if="task !== undefined && isSupport === false"
                    :watchers="task.watchers"
                    @open="(val) => open(val)"
                    @update:watchers="(userId, type) => updateWatchers(userId, type)"
                    @close="$emit('toggleTaskDetail' , task, true)"
                    :task="task"
                />
                <div class="task-detail-action" v-if="isSupport === true">
                    <ul class="task-detail-action-ul">
                        <li class="close-icon" @click="$emit('toggleTaskDetail' , task, true)">
                            <img src="@/assets/images/svg/delete.svg" />
                        </li>
                    </ul>
                </div>
            </template>
            <template #body>
                <SpinnerComp :is-spinner="isSpinner" v-if="isSpinner"/>
                <TaskDetailBody
                    v-if="task !== undefined && !isSpinner"
                    :task="task"
                    :parentTask="parentTask"
                    :clientWidth="clientWidth"
                    @open="(val) => open(val)"
                    :taskStatusIndex="10"
                    :zIndexAssigne="10"
                    :zIndexPriority="10"
                    :zIndexEstimate="10"
                    :isSupport="isSupport"
                    :subTasksArray="subTasks"
                />
            </template>
        </Sidebar>

        <Sidebar
            className="task-sub-sidebar"
            v-model:visible="isSidebar"
            :title="sidebarTitle"
            width="375px"
            :zIndex="10"
        >
            <template #body>
                <FileAndLinks
                    v-if="sidebarTitle === $t('Projects.Files_&_Links')"
                    :attachments="task.attachments"
                    :description="task.description"
                    :handleType="'task'"
                    @closeSidebar="(val) => isSidebar = val"
                    :selectedData="task"
                />
                <TaskAudioFiles
                    v-else-if="sidebarTitle === $t('Projects.audio_files')"
                    :fromWhich="'task'"
                    :key="`${task?._id}${Date.now() * Math.random() * 10000}` + 'task'"
                    :selectedData="task"
                />
            </template>
        </Sidebar>
    </div>
</template>

<script setup>
    import { computed, defineProps, inject, nextTick, onMounted, onUnmounted, provide, ref, watch } from 'vue'
    import { useGetterFunctions } from '@/composable';
    // import { useDocument } from 'vuefire';
    import { useRouter,useRoute } from 'vue-router';
    import { dbCollections } from '@/utils/FirebaseCollections';
    import taskClass from "@/utils/TaskOperations";

    import Sidebar from '@/components/molecules/Sidebar/Sidebar.vue'
    import TaskDetailNavBar from '@/components/molecules/TaskDetailNavBar/TaskDetailNavBar.vue'
    import TaskDetailTitle from '@/components/molecules/TaskDetailTitle/TaskDetailTitle.vue'
    import TaskDetailAction from '@/components/molecules/TaskDetailAction/TaskDetailAction.vue'
    import TaskDetailBody from '@/components/organisms/TaskDetailBody/TaskDetailBody.vue'
    import FileAndLinks from '@/components/molecules/FileAndLinks/FileAndLinks.vue'
    import TaskAudioFiles from '@/components/molecules/TaskAudioFiles/TaskAudioFiles.vue'
    import SpinnerComp from '@/components/atom/SpinnerComp/SpinnerComp.vue';
    import Skelaton from '@/components/atom/Skelaton/Skelaton.vue';
    const sidebarArrowIcon = require("@/assets/images/svg/sidebarclose_arrow.svg");

    import { useStore } from 'vuex';
    import { useToast } from 'vue-toast-notification';
    import * as mongoQuery from "@/utils/MongoQueries/crudOperations/crudOperations"
    import * as mongoSnap from "@/utils/MongoQueries/crudOperationsWithSanpshot/crudOperationsWithSanpshot"
    import { BSON } from 'realm-web';

    import { useI18n } from "vue-i18n";
    const { t } = useI18n();

    const emit = defineEmits(["toggleTaskDetail"]);
    const props = defineProps({
        companyId:{ type: String, default: '' },
        projectId:{ type: String, default: '' },
        sprintId:{ type: String, default: '' },
        taskId:{ type: String, default: '' },
        isTaskDetailSideBar:{type:  Boolean  ,default: false },
        zIndex:{ type: Number, default:  7},
        top:{ type: String },
        isSupport:{
            type:Boolean,
            default:false
        }
    });

    const router = useRouter();
    const route = useRoute();
    const { getters } = useStore();
    const { getUser } = useGetterFunctions();

    // const toggleTaskDetail = inject('toggleTaskDetail')
    const $toast = useToast();
    let isTaskDetailSideBar = ref(props.isTaskDetailSideBar);

    const parentTask = ref(null);
    const isSidebar = ref(false);
    const sidebarTitle = ref('');
    const subTasks = ref([])
    const subTaskLimit = ref(35);

    // PROJECT DATA
    const snap = ref(null);
    const projectData = ref({});

    const clientWidth = ref(document.documentElement.clientWidth);
    window.addEventListener('resize', (e) => {
        clientWidth.value = e.target.innerWidth;
    });

    const companyOwner = computed(() => {
        return getters["settings/companyOwnerDetail"];
    });

    const user = getUser(inject('$userId').value);
    const isSpinner = ref(true);

    // Get task details from MongoDB
    const task = ref({});

    const updateTaskName = (val) => {
        if(!val?.trim()?.length) return;
        const userData = {
            id: user.id,
            name: user.Employee_Name,
            companyOwnerId: companyOwner.value.userId,
        }

        const firebaseObj = {
            'TaskName': val
        }
        let obj = {
            'previousTaskName': task.value.TaskName,
            'userName' : userData.name
        }
        const project = {
            _id: projectData.value._id,
            CompanyId: projectData.value.CompanyId,
            lastTaskId: projectData.value.lastTaskId,
            ProjectName: projectData.value.ProjectName,
            ProjectCode: projectData.value.ProjectCode
        }

        taskClass.updateTaskName({firebaseObj, projectData: project, taskData: task.value, obj, userData})
        .then(() => {
            $toast.success(t(`Toast.Task_name_updated_successfully`), {position: "top-right"})
        })
        .catch((err) => {
            console.error(err);
        })
    }

    const updateFavourite = () => {
        taskClass.markAsFavourite({
            companyId: projectData.value.CompanyId,
            projectId: projectData.value._id,
            sprintId: props.sprintId,
            taskData: task.value,
            userId: user.id,
        })
        .then(({statusText}) => {
            $toast.success(statusText, {position: "top-right"})
        })
        .catch((error) => {
            console.error("ERROR in markAsFavourite: ", error);
        })
    }

    const updateWatchers  =  (userId, type) => {
        const operationtype = (type === "add") ? true : false
        const userData = {
            id: user.id,
            Employee_Name: user.Employee_Name,
            companyOwnerId: companyOwner.value.userId,
        }
        taskClass.updateWatcher({
            companyId: projectData.value.CompanyId,
            projectId: projectData.value._id,
            sprintId: props.sprintId,
            taskId: props.taskId,
            userId: userId,
            watchers: task.value?.watchers,
            add: operationtype,
            userData: userData,
            employeeName : getUser(userId)?.Employee_Name
        }).then(() => {
            $toast.success(t(`Toast.${type === "add" ? 'Watcher_added_successfully' : 'Watcher_removed_successfully'}`), {position: "top-right"});
        }).catch((error) => {
            console.error("ERROR in updateWatcher: ", error);
        });
    }

    const open = (val) => {
        switch (val) {
            case 'project':
                emit("toggleTaskDetail",task.value,true);
                router.push({
                    name: 'Project',
                    params: {
                        cid: props.companyId,
                        id: props.projectId
                    }
                })
                break;
            case 'sprint':
                emit("toggleTaskDetail",task.value,true);
                if(task.value.folderObjId) {
                    router.push({
                        name: 'ProjectFolderSprint',
                        params: {
                            cid: props.companyId,
                            id: props.projectId,
                            sprintId: props.sprintId,
                            folderId: task.value.folderObjId
                        }
                    });
                } else {
                    router.push({
                        name: 'ProjectSprint',
                        params: {
                            cid: props.companyId,
                            id: props.projectId,
                            sprintId: props.sprintId
                        }
                    })
                }
                break;
            case 'parent':
                if(parentTask.value) {
                   emit("toggleTaskDetail",parentTask.value);
                    if(parentTask.value.folderObjId) {
                        nextTick(() =>{router.push({
                            name: 'ProjectFolderSprintTask',
                            params: {
                                cid: props.companyId,
                                id: props.projectId,
                                sprintId: props.sprintId,
                                folderId: parentTask.value.folderObjId,
                                taskId: task.value.ParentTaskId
                            }
                        })})
                    } else {
                        nextTick(() =>{router.push({
                            name: 'ProjectSprintTask',
                            params: {
                                cid: props.companyId,
                                id: props.projectId,
                                sprintId: props.sprintId,
                                taskId: task.value.ParentTaskId
                            }
                        })})
                    }
                }

                break;
            case 'folder':
                if(task.value.folderObjId) {
                    emit("toggleTaskDetail",task.value,true);
                    router.push({
                        name: 'ProjectFolder',
                        params: {
                            cid: props.companyId,
                            id: props.projectId,
                            folderId: task.value.folderObjId
                        }
                    });
                }
                break;
            case 'filesLinks':
                isSidebar.value = true;
                sidebarTitle.value = t('Projects.files_links');
                break;
            case 'audio':
                isSidebar.value = true;
                sidebarTitle.value = t('Projects.audio_files');
                break;
            default:
                break;
        }
    }

    watch(task, (oldVal) => {
        if(oldVal && task.value && task.value.isParentTask == false && task.value.ParentTaskId){
            getParentTask()
        }
    })

    const getSprintFolderData = async (id,sprintsResult,foldersResult) => {
        try {
            return new Promise((resolve) => {
                const sprintsArray = sprintsResult?.filter(sprint => sprint.projectId === id && !sprint.folderId).map((x) => ({ ...x, id:x._id }));

                const foldersObject = foldersResult?.reduce((acc, folder) => {
                    if (folder.projectId === id) {
                        let folId = folder._id
                        acc[folId] = {
                            folderId: folId,
                            name: folder.name,
                            sprintsObj: {},
                            deletedStatusKey: folder.deletedStatusKey,
                            legacyId : folder?.legacyId ? folder?.legacyId : '',
                            id: folder._id,
                            _id: folder._id,
                        };
                    }
                    return acc;
                }, {});

                sprintsResult?.forEach(sprint => {
                    if (sprint.projectId === id && sprint.folderId && foldersObject[sprint.folderId]) {
                        sprint.folderName = foldersObject[sprint.folderId].name;
                        sprint.id = sprint._id;
                        foldersObject[sprint.folderId].sprintsObj[sprint.id] = sprint;
                    }
                });
                let allSprints = sprintsArray ? sprintsArray : []

                let allFolders = foldersObject ? foldersObject : {}

                const sprintIdToObject = {};
                allSprints.forEach(item => {sprintIdToObject[item.id] = item;});
                resolve({sprints:sprintIdToObject,folders:allFolders})
            })
        } catch (error) {
            console.error("ERROR", error);
        }
    }

    onMounted(() => {
        if(route.query?.detailTab) {
            router.replace({query: {...route.query}})
        }
        getQueryFun();
        try {
            if(task.value && Object.keys(task.value).length > 0){
                getParentTask();
            }
            let options = {
                subCollection : dbCollections.TASKS,
                watchFilter: {
                    filter: {
                        'fullDocument._id': BSON.ObjectID(props.taskId)
                    }
                }
            }

            mongoSnap.mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    console.error("ERROR in get task: ", error)
                    isSpinner.value = false;
                } else {
                    if(type === 'update'){
                        task.value = {...task.value, ...data.fullDocument};
                        isSpinner.value = false;
                    }else if(type === 'delete'){
                        task.value = {};
                        isSpinner.value = false;
                    }
                }
            })
        } catch (error) {
            console.error("ERROR in get project: ", error)
        }
    })
    provide("selectedProject", projectData);

    function getParentTask() {
        if(task?.value?.ParentTaskId) {
            const queryRef = mongoQuery.mongodbCrudOperations({
                type: "findOne",
                collection: dbCollections.TASKS,
                data: [
                    { _id: BSON.ObjectID(task.value.ParentTaskId) }
                ]
            });

            queryRef.then((response) => {
                parentTask.value = response;
            })
        }
    }

    function getQueryFun () {
        let query = [
            {
                $match: {_id : BSON.ObjectID(props.projectId)}
            },
            {
                $lookup: {
                    from: dbCollections.TASKS,
                    let: { taskId: BSON.ObjectID(props.taskId) },
                    pipeline: [
                        {
                            $match: {
                                $expr: {
                                    $eq: ["$_id", "$$taskId"]
                                }
                            }
                        },
                        {
                            $lookup: {
                                from: dbCollections.SPRINTS,
                                localField: "sprintId",
                                foreignField: "_id",
                                as: "sprintDetails"
                            }
                        },
                        {
                            $addFields: {
                                sprintName: {
                                    $arrayElemAt: ["$sprintDetails.name", 0]
                                }
                            }
                        },
                        {
                            $lookup: {
                                from: dbCollections.FOLDERS,
                                localField: "folderObjId",
                                foreignField: "_id",
                                as: "folderDetails"
                            }
                        },
                        {
                            $addFields: {
                                folderName: {
                                    $arrayElemAt: ["$folderDetails.name", 0]
                                }
                            }
                        }
                    ],
                    as: "tasks"
                }
            },
            {
                // Add sprintsfolders and sprintsObj at the project level
                $addFields: {
                    sprintsObj: { $arrayElemAt: ["$tasks.sprintDetails", 0] }, // Get first element of the sprint
                    sprintsfolders: { $arrayElemAt: ["$tasks.folderDetails", 0] } // Get first element of the folder
                }
            },
            {
                $lookup: {
                    from: dbCollections.TASKS,
                    let: { parentTaskId: props.taskId },
                    pipeline: [
                        {
                            $match: {
                                $expr: {
                                    $eq: ["$ParentTaskId", "$$parentTaskId"],
                                },
                                deletedStatusKey : {$in : [0,undefined]}
                            }
                        },
                        { $sort: {createdAt: -1,_id: 1} },
                        { $limit: subTaskLimit.value }
                    ],
                    as: "subtasks"
                }
            }
        ];
        let getQuery = {
            type : "aggregate",
            collection : dbCollections.PROJECTS,
            data:[query]
        }
        mongoQuery.mongodbCrudOperations(getQuery).then((response) => {
            const sprint = response[0].sprintsObj;
            const folder = response[0].sprintsfolders;
            getSprintFolderData(response[0]._id,sprint,folder).then((resp) => {
                response[0].sprintsObj = resp.sprints
                response[0].sprintsfolders = resp.folders
                isSpinner.value = false;
                projectData.value = response[0];
                task.value = response[0].tasks[0] || {};
                subTasks.value = response[0].subtasks || [];
            })
        })
    }

    onUnmounted(() => {
        // DETACH
        if(snap.value !== null) {
            snap.value();
        }
    })
</script>
<style src="./style.css"></style>