export default {
    install(app) {
        // Silence Is Golden
        app.provide("addSubscription", () => {
            console.log("Silence Is Golden");
        });
        app.provide("displayUpgrade", () => {
            console.log("Silence Is Golden");
        });
        app.provide("paymentInit", () => {
            console.log("Silence Is Golden");
        });
    }
};
